import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/docs.tsx";
export const pageTitle = "Service registration for discovery";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const RequiredDependencies = makeShortcode("RequiredDependencies");
const Tip = makeShortcode("Tip");
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "service-registration-for-discovery",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#service-registration-for-discovery",
        "aria-label": "service registration for discovery permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Service registration for discovery`}</h1>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#zookeeper-based-service-registration-with-zookeeperupdatinglistener"
        }}>{`ZooKeeper-based service registration with ZooKeeperUpdatingListener`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#eureka-based-service-registration-with-eurekaupdatinglistener"
        }}>{`Eureka-based service registration with EurekaUpdatingListener`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#consul-based-service-registration-with-consulupdatinglistener"
        }}>{`Consul-based service registration with ConsulUpdatingListener`}</a></li>
    </ul>
    <p>{`You can use `}<a parentName="p" {...{
        "href": "type://ServerListener:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerListener.html"
      }}>{`type://ServerListener`}</a>{` to register the information of Armeria servers when they start so that
a client uses the information to distribute its requests to the servers autonomously, unlike
traditional server-side load balancing where the requests go through a dedicated load balancer such as
`}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Multilayer_switch#Layer_4%E2%80%937_switch,_web_switch,_or_content_switch"
      }}>{`L4 and L7 switches`}</a>{`.`}</p>
    <p>{`Armeria provides 2 `}<a parentName="p" {...{
        "href": "type://ServerListener:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerListener.html"
      }}>{`type://ServerListener`}</a>{` implementations for service registration:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://ZooKeeperUpdatingListener:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/zookeeper/ZooKeeperUpdatingListener.html"
        }}>{`type://ZooKeeperUpdatingListener`}</a>{` which registers to `}<a parentName="li" {...{
          "href": "https://zookeeper.apache.org/"
        }}>{`ZooKeeper`}</a>{`
as an ephemeral node.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "type://EurekaUpdatingListener:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/eureka/EurekaUpdatingListener.html"
        }}>{`type://EurekaUpdatingListener`}</a>{` which registers to `}<a parentName="li" {...{
          "href": "https://github.com/Netflix/eureka/"
        }}>{`Eureka`}</a>{`.`}</li>
    </ul>
    <h2 {...{
      "id": "zookeeper-based-service-registration-with-zookeeperupdatinglistener",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#zookeeper-based-service-registration-with-zookeeperupdatinglistener",
        "aria-label": "zookeeper based service registration with zookeeperupdatinglistener permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ZooKeeper-based service registration with `}<inlineCode parentName="h2">{`ZooKeeperUpdatingListener`}</inlineCode></h2>
    <p>{`First, You need the `}<inlineCode parentName="p">{`armeria-zookeeper3`}</inlineCode>{` dependency:`}</p>
    <RequiredDependencies boms={[{
      groupId: 'com.linecorp.armeria',
      artifactId: 'armeria-bom'
    }]} dependencies={[{
      groupId: 'com.linecorp.armeria',
      artifactId: 'armeria-zookeeper3'
    }]} mdxType="RequiredDependencies" />
    <p>{`Then, use `}<a parentName="p" {...{
        "href": "type://ZooKeeperUpdatingListener:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/zookeeper/ZooKeeperUpdatingListener.html"
      }}>{`type://ZooKeeperUpdatingListener`}</a>{` and `}<a parentName="p" {...{
        "href": "type://ZooKeeperRegistrationSpec:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/zookeeper/ZooKeeperRegistrationSpec.html"
      }}>{`type://ZooKeeperRegistrationSpec`}</a>{` to register your server
to a ZooKeeper cluster:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.server.Server;
import com.linecorp.armeria.server.zookeeper.ZooKeeperRegistrationSpec;
import com.linecorp.armeria.server.zookeeper.ZooKeeperUpdatingListener;

String zkConnectionStr = "myZooKeeperHost:2181";
String znodePath = "/myProductionEndpoints";
String serviceName = "catalog";
ZooKeeperRegistrationSpec registrationSpec =
        ZooKeeperRegistrationSpec.curator(serviceName);
ZooKeeperUpdatingListener listener =
        ZooKeeperUpdatingListener.builder(zkConnectionStr, znodePath, registrationSpec)
                                 .sessionTimeoutMillis(10000)
                                 .build();
Server server = ...
server.addListener(listener);
server.start();
`}</code></pre>
    <p>{`The `}<a parentName="p" {...{
        "href": "type://ZooKeeperRegistrationSpec:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/zookeeper/ZooKeeperRegistrationSpec.html"
      }}>{`type://ZooKeeperRegistrationSpec`}</a>{` is used to convert the information of your server to a binary
representation. The `}<a parentName="p" {...{
        "href": "type://ZooKeeperUpdatingListener:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/zookeeper/ZooKeeperUpdatingListener.html"
      }}>{`type://ZooKeeperUpdatingListener`}</a>{` registers the binary representation to the specified
znode as a member of the cluster when your server starts up. Each server will represent itself as
`}<a parentName="p" {...{
        "href": "https://zookeeper.apache.org/doc/r3.4.10/zookeeperOver.html#Nodes+and+ephemeral+nodes"
      }}>{`an EPHEMERAL node`}</a>{`,
which means when a server stops or a network partition between your server and ZooKeeper cluster occurs,
the node of the server that became unreachable will be deleted automatically by ZooKeeper.`}</p>
    <p><a parentName="p" {...{
        "href": "type://ZooKeeperRegistrationSpec#curator(String):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/zookeeper/ZooKeeperRegistrationSpec.html#curator(java.lang.String)"
      }}>{`type://ZooKeeperRegistrationSpec#curator(String)`}</a>{` uses the format of
`}<a parentName="p" {...{
        "href": "https://curator.apache.org/curator-x-discovery/index.html"
      }}>{`Curator Service Discovery`}</a>{` which is compatible
with `}<a parentName="p" {...{
        "href": "https://cloud.spring.io/spring-cloud-zookeeper/reference/html/"
      }}>{`Spring Cloud Zookeeper`}</a>{`.
You can use `}<a parentName="p" {...{
        "href": "type://ZooKeeperRegistrationSpec#serverSets():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/zookeeper/ZooKeeperRegistrationSpec.html#serverSets()"
      }}>{`type://ZooKeeperRegistrationSpec#serverSets()`}</a>{` that applies the format of
`}<a parentName="p" {...{
        "href": "https://twitter.github.io/finagle/docs/com/twitter/serverset.html"
      }}>{`Finagle ServerSets`}</a>{`.
If you want to use your own format, you can implement the `}<a parentName="p" {...{
        "href": "type://ZooKeeperRegistrationSpec:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/zookeeper/ZooKeeperRegistrationSpec.html"
      }}>{`type://ZooKeeperRegistrationSpec`}</a>{`.`}</p>
    <p>{`In the example above, we used the ZooKeeper connection string(`}<inlineCode parentName="p">{`"myZooKeeperHost:2181"`}</inlineCode>{`) to connect to
the ZooKeeper cluster. Instead, you can use an existing
`}<a parentName="p" {...{
        "href": "https://curator.apache.org/apidocs/org/apache/curator/framework/CuratorFramework.html"
      }}>{`CuratorFramework`}</a>{`
instance.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import org.apache.curator.framework.CuratorFramework;

CuratorFramework client = ...
String znodePath = ...
ZooKeeperRegistrationSpec registrationSpec = ...
ZooKeeperUpdatingListener listener =
        ZooKeeperUpdatingListener.builder(client, znodePath, registrationSpec)
                                 .build();
Server server = ...
server.addListener(listener);
server.start();
`}</code></pre>
    <p>{`For more information, please refer to the API documentation of the
`}<a parentName="p" {...{
        "href": "https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/zookeeper/package-summary.html"
      }}>{`com.linecorp.armeria.server.zookeeper`}</a>{` package.`}</p>
    <Tip mdxType="Tip">
      <p>{`See `}<a parentName="p" {...{
          "href": "/docs/client-service-discovery#zookeeper-based-service-discovery-with-zookeeperendpointgroup"
        }}>{`ZooKeeper service discovery`}</a>{`.`}</p>
    </Tip>
    <h2 {...{
      "id": "eureka-based-service-registration-with-eurekaupdatinglistener",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#eureka-based-service-registration-with-eurekaupdatinglistener",
        "aria-label": "eureka based service registration with eurekaupdatinglistener permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Eureka-based service registration with `}<inlineCode parentName="h2">{`EurekaUpdatingListener`}</inlineCode></h2>
    <p>{`First, You need the `}<inlineCode parentName="p">{`armeria-eureka`}</inlineCode>{` dependency:`}</p>
    <RequiredDependencies boms={[{
      groupId: 'com.linecorp.armeria',
      artifactId: 'armeria-bom'
    }]} dependencies={[{
      groupId: 'com.linecorp.armeria',
      artifactId: 'armeria-eureka'
    }]} mdxType="RequiredDependencies" />
    <p>{`Then, use `}<a parentName="p" {...{
        "href": "type://EurekaUpdatingListener:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/eureka/EurekaUpdatingListener.html"
      }}>{`type://EurekaUpdatingListener`}</a>{` to register your server to the Eureka:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.server.Server;
import com.linecorp.armeria.server.ServerBuilder;
import com.linecorp.armeria.server.eureka.EurekaUpdatingListener;

EurekaUpdatingListener listener =
        EurekaUpdatingListener.of("https://eureka.com:8001/eureka/v2");

ServerBuilder sb = Server.builder();
sb.serverListener(listener);
sb.build().start();
`}</code></pre>
    <p>{`If you want to specify the `}<a parentName="p" {...{
        "href": "https://github.com/Netflix/eureka/wiki/Eureka-REST-operations"
      }}>{`properties`}</a>{`
of the `}<a parentName="p" {...{
        "href": "type://Server:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/Server.html"
      }}>{`type://Server`}</a>{`, use `}<a parentName="p" {...{
        "href": "type://EurekaUpdatingListenerBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/eureka/EurekaUpdatingListenerBuilder.html"
      }}>{`type://EurekaUpdatingListenerBuilder`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.server.eureka.EurekaUpdatingListenerBuilder;

EurekaUpdatingListenerBuilder builder =
        EurekaUpdatingListener.builder("https://eureka.com:8001/eureka/v2")
                              .instanceId("i-00000000")
                              .hostname("armeria.service.1")
                              // If ipAddr is not specified, it's automatically filled
                              // using SystemInfo.defaultNonLoopbackIpV4Address().
                              .ipAddr("192.168.10.10")
                              .vipAddress("armeria.service.com:8080");
                              
EurekaUpdatingListener listener = builder.build();
ServerBuilder sb = Server.builder();
sb.serverListener(listener);
sb.build().start();
`}</code></pre>
    <p>{`For more information, please refer to the API documentation of the
`}<a parentName="p" {...{
        "href": "https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/eureka/package-summary.html"
      }}>{`com.linecorp.armeria.server.eureka`}</a>{` package.`}</p>
    <Tip mdxType="Tip">
      <p>{`See `}<a parentName="p" {...{
          "href": "/docs/client-service-discovery#eureka-based-service-discovery-with-eurekaendpointgroup"
        }}>{`Eureka service discovery`}</a>{`.`}</p>
    </Tip>
    <h2 {...{
      "id": "consul-based-service-registration-with-consulupdatinglistener",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#consul-based-service-registration-with-consulupdatinglistener",
        "aria-label": "consul based service registration with consulupdatinglistener permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><a parentName="h2" {...{
        "href": "https://www.consul.io"
      }}>{`Consul`}</a>{`-based service registration with `}<inlineCode parentName="h2">{`ConsulUpdatingListener`}</inlineCode></h2>
    <p>{`First, You need the `}<inlineCode parentName="p">{`armeria-consul`}</inlineCode>{` dependency:`}</p>
    <RequiredDependencies boms={[{
      groupId: 'com.linecorp.armeria',
      artifactId: 'armeria-bom'
    }]} dependencies={[{
      groupId: 'com.linecorp.armeria',
      artifactId: 'armeria-consul'
    }]} mdxType="RequiredDependencies" />
    <p>{`Then, use `}<a parentName="p" {...{
        "href": "type://ConsulUpdatingListener:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/consul/ConsulUpdatingListener.html"
      }}>{`type://ConsulUpdatingListener`}</a>{` to register your server to the Consul:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.server.Server;
import com.linecorp.armeria.server.ServerBuilder;
import com.linecorp.armeria.server.consul.ConsulUpdatingListener;

ConsulUpdatingListener listener =
    ConsulUpdatingListener.of("http://my-consul.com:8500", "my-service");

ServerBuilder sb = Server.builder();
sb.serverListener(listener);
sb.build().start();
`}</code></pre>
    <p>{`If you want to specify an `}<a parentName="p" {...{
        "href": "https://www.consul.io/api/agent/check#http"
      }}>{`HTTP check`}</a>{` or
`}<a parentName="p" {...{
        "href": "https://www.consul.io/api-docs/agent/service#tags"
      }}>{`tags`}</a>{` to the `}<a parentName="p" {...{
        "href": "type://Server:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/Server.html"
      }}>{`type://Server`}</a>{`,
use `}<a parentName="p" {...{
        "href": "type://ConsulUpdatingListenerBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/consul/ConsulUpdatingListenerBuilder.html"
      }}>{`type://ConsulUpdatingListenerBuilder`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.linecorp.armeria.server.consul.ConsulUpdatingListenerBuilder;

ConsulUpdatingListenerBuilder builder =
    ConsulUpdatingListener.builder("http://my-consul.com:8500", "my-service")
                          .check("http://my.hostname.local:8080/internal/l7check")
                          .tags("production", "v1");
                              
ConsulUpdatingListener listener = builder.build();
ServerBuilder sb = Server.builder();
sb.serverListener(listener);
sb.build().start();
`}</code></pre>
    <p>{`For more information, please refer to the API documentation of the
`}<a parentName="p" {...{
        "href": "https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/consul/package-summary.html"
      }}>{`com.linecorp.armeria.server.consul`}</a>{` package.`}</p>
    <Tip mdxType="Tip">
      <p>{`See `}<a parentName="p" {...{
          "href": "/docs/client-service-discovery#consul-based-service-discovery-with-consulendpointgroup"
        }}>{`Consul service discovery`}</a>{`.`}</p>
    </Tip>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      